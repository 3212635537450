import { questionKey } from "@/utils/question";
import debounce from "lodash/debounce";
import { CustomizedScriptMixin } from "@/mixins/customized-script";
import isEmpty from "lodash/isEmpty";

export const QuestionMixin = {
  mixins: [CustomizedScriptMixin],
  props: {
    loading: {
      type: Boolean,
    },
    lang: {
      type: String,
    },
    question: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [String, Number, Object, Date],
    },
    showQuestionCode: {
      type: [String, Number, Boolean],
      default: false,
    },
    showAnswerCode: {
      type: [String, Number, Boolean],
      default: false,
    },
    shouldFocus:{
      type: Boolean,
      default: true
    },
    sid: {
      type: Number,
      default: 0
    },
    result: {
      type: Object,
      default: () => ({})
    },
    resultQcode: {
      type: Object,
      default: () => ({})
    },
    lastAction: {
      type: String,
      default: 'next-page'
    },
    respondent: {
      type: Object,
      default: () => ({})
    },
    reviewMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExclusive: false,
      customizedRules: {},
      customizedProps: {},
      width: 'is-half'
    };
  },
  computed: {
    qOpts() {
      return this.question?.options || {};
    },
    validationRules() {

      if(!isEmpty(this.customizedRules)) return this.customizedRules

      const rules = {};
      //FIXME: rename validation to regex
      if (this.qOpts.validation) rules.regex = this.qOpts.validation;

      if (this.qOpts.mandatory) rules.mandatory = { type: this.question.type };

      if (this.question.type === "MI" && this.qOpts.type === "text") {
        if (this.qOpts.min)
          rules.min_length = { type: this.question.type, min: this.qOpts.min };
        if (this.qOpts.max)
          rules.max_length = { type: this.question.type, max: this.qOpts.max };
      } else if (
        (this.qOpts.min || this.qOpts.min === 0) &&
        this.qOpts.max &&
        !this.isExclusive
      )
        rules.between = {
          type: this.question.type,
          min: this.qOpts.min,
          max: this.qOpts.max,
        };
      else if ((this.qOpts.min || this.qOpts.min === 0) && !this.isExclusive)
        rules.min = {
          type: this.question.type,
          min: this.qOpts.min,
        };
      else if (this.qOpts.max)
        rules.max = {
          type: this.question.type,
          max: this.qOpts.max,
        };
      return rules;
    },

    questionHelp() {
      if (this.validationRules.between && this.question.type === "FU") {
        return this.$i18n.t("validations.messages.file_between", {
          min: this.validationRules.between.min,
          max: this.validationRules.between.max,
        });
      }

      if (this.qOpts.ext) {
        return this.$i18n.t("validations.messages.ext", {
          mimes: this.qOpts.ext,
        });
      }

      if (this.qOpts.size) {
        return this.$i18n.t("validations.messages.size", {
          size: this.qOpts.size,
        });
      }

      if (this.validationRules.between) {
        const values = {
          min: this.validationRules.between.min,
          max: this.validationRules.between.max,
        };
        return this.$i18n.te(
          `validations.messages.${this.question.type}.between`
        )
          ? this.$i18n.t(
              `validations.messages.${this.question.type}.between`,
              values
            )
          : this.$i18n.t("validations.messages.between", values);
      }

      if (this.validationRules.max) {
        const values = {
          max: this.validationRules.max.max,
        };
        return this.$i18n.te(`validations.messages.${this.question.type}.max`)
          ? this.$i18n.t(
              `validations.messages.${this.question.type}.max`,
              values
            )
          : this.$i18n.t("validations.messages.max", values);
      }

      if (this.validationRules.min) {
        const values = {
          min: this.validationRules.min.min,
        };
        return this.$i18n.te(`validations.messages.${this.question.type}.min`)
          ? this.$i18n.t(
              `validations.messages.${this.question.type}.min`,
              values
            )
          : this.$i18n.t("validations.messages.min", values);
      }

      if (this.validationRules.max_length) {
        return this.$i18n.t("validations.messages.max_length", {
          length: this.validationRules.max_length.max,
        });
      }

      if (this.validationRules.min_length) {
        return this.$i18n.t("validations.messages.min_length", {
          length: this.validationRules.min_length.min,
        });
      }

      if (this.question.type === "MI" && this.qOpts.type === "number" && this.qOpts.total) {
        return this.$i18n.t("validations.messages.MI.total", {
          total: this.qOpts.total,
        });
      }

      if (this.validationRules.mandatory) {
        if (this.question.type === "C" && this.$i18n.te(`validations.messages.C.mandatory`)) {
          return this.$i18n.t(`validations.messages.C.mandatory`);
        }
        return this.$i18n.t("validations.messages.mandatory");
      }

      if (this.validationRules.credential) {
        return this.$i18n.t("validations.messages.credential");
      }
    }
  },
  methods: {
    decodeHtml(html) {
      const txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    questionKey: questionKey,
    autoFocus: debounce(function() {
      if (this.isIos) return;
      const el = document.querySelectorAll(
        "INPUT[type=text],INPUT[type=number],TEXTAREA"
      )[0];
      if (el && this.shouldFocus) {
        el.focus();
        el.click();
        // el.scrollIntoView();
      }
    }, 250),
    clearData(){
      if (typeof this.ownClearData === "function"){
        this.ownClearData()
      }
      this.selected = []
      this.$emit('input', Object.keys(this.value).reduce((acc, curr) => ({...acc, [curr]: ''}), {}))
    },
    applySurveyGroupResult() {
			if (!this.respondent?.skipped_questions?.includes(this.question.qid)) return
      const changes = Object.keys(this.respondent.result)
				.filter(key => key.split('_')?.[0] == this.question.qid)
				.reduce((acc, curr) => {
					acc[curr] = this.respondent.result[curr]
					return acc
				}, {})
      this.$emit('input', changes)
    },
  },
  mounted() {
    setTimeout(() => {
      const numberOfQuestions = document.getElementsByClassName('question').length;
      if(numberOfQuestions === 1){
        this.$emit("mounted", this.width)
        return;
      }else{
        const classEnum = {
          'is-1': 1,
          'is-2': 2,
          'is-3': 3,
          'is-4': 4,
          'is-half': 5,
          'is-6': 6,
          'is-7': 7,
          'is-8': 8,
          'is-9': 9,
          'is-10': 10,
          'is-11': 11,
          'is-12': 12
        }
        const previousQuestionClass = Array.from(document.getElementsByClassName('question'))
        .reduce((carr, curr) => [
          ...carr,
          Array.from(curr.classList).find(el => el.startsWith('is'))
        ], [])
        const theLargestWidth = previousQuestionClass.reduce(function(c, n) {
          return classEnum[c] > classEnum[n] ? c : n;
        }, 'is-1');
        this.width = theLargestWidth
        this.$emit("mounted", this.width);
      }
    }, 250);    

    this.$root.$on('validation-error', this.clearData)
    this.autoFocus();
  },
  async beforeMount(){
    this.$emit('ready', false)
    await new Promise(r => setTimeout(r, 250));
    await this.preload(this, this.resultQcode, this.lastAction);
    await new Promise(r => setTimeout(r, 150));
		this.applySurveyGroupResult()
    this.$emit('ready', true)
  }
};
